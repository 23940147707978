import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const OmGiJoeNoPage = () => {
    return (
        <Layout>
            <SEO title="Om nettstedet" />
            <div className="container">
                <div className="w-full mt-14 md:px-12 lg:px-20 xl:px-32 2xl:px-44">
                    <h1 className="mb-10">Om gijoe.no</h1>
                    <h2 className="mb-2">Hva er GI Joe?</h2>
                    <div className="mb-10">
                        <p>
                            GI Joe ble opprinnelig lansert på 60-tallet som store action-figurer (30 cm). Dette ble en grei suksess, men etter stigende motstand mot krigsbaserte leker ble dette etter hvert lagt på hylla.
                            I 1982, inspirert av suksessen med figurene til Star Wars, ble GI Joe relansert som en mindre figur (9,5cm) som “A real American Hero”. Da ble det laget flere forskjellige figurer som hadde egne navn og særpreg. Det ble hvert år gitt ut nye figurer, og etter hvert kom det også til en tegneserie og en tv-serie som mange kjenner til.
                        </p>
                        <p className="mt-5">    
                            I Norge kjenner vi også GI Joe som “Action Force”.
                            Det ble laget en historie rundt figurene, og mange kjenner til den evige kampen mellom “the good guys”, GI Joe, og den onde organisasjonen, Cobra.
                            I senere år har det blitt laget påkostede Hollywood-filmer med flere kjente skuespillere.
                            Disse gamle figurene har blitt et populært samlerobjekt. Noen ønsker kun noen få figurer de husker fra barndommen, mens andre prøver å få tak i alle som ble laget.
                        </p>
                    </div>
                    <h2 className="mb-2">Betingelser</h2>
                    <p className="mb-10">
                        Alle salg på gijoe.no gjøres av privatpersoner, og alle salgsbetingelser er i henhold til dette. Alt selges “som vist”, men det finnes en utfyllende beskrivelse av tilstand på figuren på kjøpssiden.<br/>
                        <br/>
                        Alle bilder av figurer er av det gjeldende salgsobjektet, og det benyttes ikke stock-bilder på kjøpssidene.
                    </p>

                    <h2 className="mb-2">Frakt</h2>
                    <p className="mb-10">
                        Det tilkommer frakt på alle priser etter postens satser, og kjøper bestemmer type sending (med/uten sporing etc.). Det er ingen påslag på fraktprisene.
                    </p>

                    <h2 className="mb-2">Betaling</h2>
                    <p className="mb-10">
                        Betaling foregår med Vipps. Når bestilling er mottatt vil du bli kontaktet med betalingsdetaljer.
                    </p>

                    <h2 className="mb-2">Kontakt</h2>
                    <p className="mb-10">
                        Benytt denne epostadressen for kontakt: <a href="mailto:kontakt@gijoe.no">kontakt@gijoe.no</a>
                    </p>
                </div>
            </div>
        </Layout>
    )
}

export default OmGiJoeNoPage